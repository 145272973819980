<template>
  <div
    v-if="checkpoints.length"
    data-test="heckpoint-list"
    class="d-flex align-start justify-start checkpoint-list"
  >
    <div
      v-for="checkpoint in checkpoints"
      :key="checkpoint.id"
      :style="`width: ${cellWidth}px`"
      class="checkpoint-list__cell py-4"
    >
      <MeetingCheckpointsCell
        :item="checkpoint"
        @click="$emit('click', $event)"
      />
    </div>
  </div>
  <div
    v-else
    class="px-3"
  >
    Для данного сотрудника встречи не запланированы
  </div>
</template>

<script>
import MeetingCheckpointsCell from '@/components/meeting/MeetingCheckpointsCell.vue';

export default {
  name: 'MeetingCheckpointsList',
  components: {
    MeetingCheckpointsCell,
  },
  props: {
    checkpoints: {
      type: Array,
      required: true,
    },
    cellWidth: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
 .checkpoint-list .checkpoint-list__cell:not(:last-child) {
    border-right: 1px solid #ebeced
 }
</style>
