<template>
  <VContainer
    fluid
    class="report-meeting"
  >
    <DialogWrapper
      v-if="moreInfoDialog"
      v-model="moreInfoDialog"
      fullscreen
      @click:outside="moreInfoDialog = false"
    >
      <MeetingMoreInfo
        :checkpoint="activeCheckpoint"
        :staff="staffMap[activeCheckpoint.staff_id]"
        :extra-staff-map="extraStaffMap"
        @close="closeMoreInfo"
        @showCreateReport="showCreateReport"
      />
    </DialogWrapper>
    <DialogWrapper
      v-if="createReportDialog"
      v-model="createReportDialog"
      fullscreen
      @click:outside="createReportDialog = false"
    >
      <MeetingCreateReportForm
        :checkpoint="activeCheckpoint"
        :staff="staffMap[activeCheckpoint.staff_id]"
        :extra-staff-map="extraStaffMap"
        :problems-map="problemsMap"
        @close="closeCreateReport"
        @created="reportCreated"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="briefInfoDialog"
      width="396"
      hide-overlay
      @click:outside="briefInfoDialog = false"
    >
      <MeetingBriefInfo
        v-if="briefInfoDialog"
        :checkpoint="activeCheckpoint"
        :staff="staffMap[activeCheckpoint.staff_id]"
        :extra-staff-map="extraStaffMap"
        @showMore="showMoreInfo"
        @showCreateReport="showCreateReport"
        @close="briefInfoDialog = false"
      />
    </DialogWrapper>

    <VRow class="align-center">
      <VCol cols="9">
        <h1 class="mb-2 tt-text-headline-1">
          Встречи
        </h1>
        <div class="tt-text-body-2 tt-light-mono-46--text mb-6">
          Настраивайте шаблон встречи, смотрите список назначенных и заполняйте отчеты по прошедшим встречам.
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol class="py-0">
        <VRow class="mb-4 align-end">
          <VCol
            :cols="needMeetingTags ? 6: 4"
            class="py-0"
            data-test="tt-select"
            data-test-label="select-tags"
          >
            <SelectTags
              v-if="needMeetingTags"
              v-model="selectedTags"
              label="Тег"
              :items="tags"
            />
            <TTSelect
              v-else
              ref="locationDropDown"
              class="tt-select-attach"
              attach
              :value="currentLocationId"
              :items="locations"
              item-text="name"
              item-value="id"
              return-object
              large
              label="Локация"
              :menu-props="{ auto : true }"
              placeholder="Выбор локации"
              @change="handleLocation"
            >
              <template #selection="{item,disabled}">
                <TextTemplate
                  class="v-select__selection v-select__selection--comma"
                  :disabled="disabled"
                  :text="item.name"
                  :show-prefix="!item.is_active && !!item.id"
                />
              </template>
              <template #item="{item}">
                <TextTemplate
                  :text="item.name"
                  :show-prefix="!item.is_active && !!item.id"
                />
              </template>
            </TTSelect>
          </VCol>
          <VCol
            :cols="needMeetingTags ? 3: 4"
            class="py-0"
          >
            <TTSelect
              v-model="filter.is_expired"
              :items="filterList"
              large
              label="Статус встречи"
              placeholder="Выбор отображения"
              @change="handleFilter"
            />
          </VCol>
          <VCol
            :cols="needMeetingTags ? 3: 4"
            class="py-0"
          >
            <TTTextField
              v-model="filter.search"
              append-icon="fal fa-search"
              large
              label="Поиск сотрудника"
              placeholder="Поиск по ФИО"
              data-test="input-search"
              @input="handleStaffTextSearch"
            />
          </VCol>
        </VRow>
      </VCol>
      <VCol class="py-0 flex-grow-0">
        <VMenu
          content-class="v-menu-shadow"
          offset-y
          :nudge-bottom="8"
          bottom
          max-width="466"
          :close-on-content-click="false"
          left
        >
          <template #activator="{ on }">
            <div class="mt-5">
              <VBadge
                :value="selectedFilters"
                dark
                color="black"
                :content="selectedFilters"
                overlap
              >
                <VBtn
                  fab
                  depressed
                  large
                  color="#F5F6F6"
                  :ripple="false"
                  class="table-menu-button"
                  data-test="report-meeting-button-show-menu"
                  v-on="on"
                >
                  <VIcon
                    size="19"
                    color="black"
                  >
                    fal fa-sliders-h
                  </VIcon>
                </VBtn>
              </VBadge>
            </div>
          </template>
          <VCard class="v-menu-card">
            <VCardText class="pa-6">
              <VRow>
                <VCol
                  class="pt-3 pb-3"
                  cols="9"
                >
                  <p class="tt-text-body-2 mb-0">
                    Поиск по HR’у, руководителю, наставнику
                  </p>
                </VCol>
                <VCol
                  v-if="filter.connected_staff_search.length"
                  cols="3"
                  class="pt-3 text-right"
                >
                  <span
                    class="tt-dark-blue--text"
                    @click="resetSideStaffSearch"
                  >
                    Сбросить
                  </span>
                </VCol>
                <VCol
                  class="pt-0 pb-6"
                  cols="12"
                >
                  <TTTextField
                    v-model="filter.connected_staff_search"
                    placeholder="Поиск по ФИО"
                    large
                    hide-details
                    single-line
                    append-icon="fal fa-search"
                    data-test="report-meeting-input-search"
                    @input="handleSideStaffTextSearch"
                  />
                </VCol>
                <VCol
                  class="pt-0 pb-3"
                  cols="6"
                >
                  <p class="tt-text-body-2 mb-0">
                    Период выхода на работу
                  </p>
                </VCol>
                <VCol
                  v-if="filter.start_at_from.length"
                  cols="6"
                  class="pt-0 text-right"
                >
                  <span
                    class="tt-dark-blue--text"
                    @click="handlePeriodChange([])"
                  >
                    Сбросить
                  </span>
                </VCol>
                <VCol
                  cols="12"
                  class="py-0 pb-3"
                >
                  <SRangePicker
                    append-icon="fal fa-calendar-alt"
                    placeholder="Выберите период"
                    :value="searchPeriod"
                    :max-date="dateNow"
                    single-line
                    hide-details="auto"
                    data-test="report-meeting-search-period"
                    @input="handlePeriodChange"
                  />
                </VCol>
              </VRow>
            </VCardText>
          </VCard>
        </VMenu>
      </VCol>
    </VRow>
    <VRow v-if="contentStatus === contentStatuses.SHOW_TABLE">
      <VCol>
        <TTDataTable
          :items="staff"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          :loading="!dataLoaded"
          data-test="checkpoints-table"
        >
          <template #header.report="{ header }">
            <div
              ref="tutorListCell"
              v-resize="onResize"
              class="w-100 text-center report-meeting__header"
            >
              {{ header.text }}
              <template v-if="showHeaderArrows">
                <div
                  v-if="startRow"
                  class="report-meeting__header-left black--text text--link d-flex"
                  data-test="table-arrow-left"
                  @click="handleLeft"
                >
                  <VIcon
                    small
                    color="#0B1218"
                    class="mr-2"
                    :ripple="false"
                  >
                    fal fa-chevron-left
                  </VIcon>
                  <p class="mb-0">
                    К более ранним
                  </p>
                </div>
                <div
                  v-if="startRow + CHECKPOINT_ROWS < maxTemplateLength"
                  class="report-meeting__header-right black--text text--link d-flex"
                  data-test="table-arrow-right"
                  @click="handleRight"
                >
                  <p class="mb-0">
                    К более поздним
                  </p>
                  <VIcon
                    small
                    color="#0B1218"
                    :ripple="false"
                    class="ml-2"
                  >
                    fal fa-chevron-right
                  </VIcon>
                </div>
              </template>
            </div>
          </template>
          <template #item="{item}">
            <tr>
              <td
                class="cursor--pointer"
                data-test="table-staff-cell"
                @click="$router.push(`/staff/${item.id}`)"
              >
                <div class="d-flex align-start">
                  <NewSAvatar
                    data-test="table-avatar"
                    :img="item.photo_url"
                    :name="`${item.last_name} ${item.first_name}`"
                    class="mr-3"
                  />
                  <div>
                    <p class="mb-0">
                      {{ item.last_name }} {{ item.first_name }}
                    </p>
                    <div class="tt-black--text text--lighten-2">
                      <TTTooltip
                        bottom
                        max-width="300"
                      >
                        <template #activator="{ on, attrs }">
                          <p
                            v-bind="attrs"
                            class="tt-black--text text--lighten-2 ellipsis-two-lines ellipsis mb-0"
                            v-on="on"
                          >
                            <template v-if="needMeetingTags">
                              <span
                                data-test="name-position"
                                :data-test-label="item.id"
                              >{{ item.position }}</span>
                            </template>
                            <template v-else>
                              {{ currentLocationName }}<span v-if="item.position">, {{ item.position }}</span>
                            </template>
                          </p>
                        </template>
                        <p class="mb-0">
                          <template v-if="needMeetingTags">
                            {{ item.position }}
                          </template>
                          <template v-else>
                            {{ currentLocationName }}<span v-if="item.position">, {{ item.position }}</span>
                          </template>
                        </p>
                      </TTTooltip>
                    </div>
                  </div>
                </div>
              </td>
              <td
                class="py-0 px-1"
                style="vertical-align: top!important"
                data-test="table-checkpoints-cell"
              >
                <MeetingCheckpointsList
                  :checkpoints="prepareCheckpoints(item.checkpoints)"
                  :cell-width="cellWidth"
                  @click="showBriefInfo"
                />
              </td>
            </tr>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
    <VRow v-else-if="!currentLocationId">
      <VCol cols="12">
        <h2 class="mb-3">
          Не задана локация
        </h2>
        <p class="mb-0">
          Выберите локацию для получения данных.
        </p>
      </VCol>
    </VRow>
    <VRow v-else-if="contentStatus === contentStatuses.SHOW_MEETING">
      <VCol cols="12">
        <h2 class="mb-3">
          У вас нет запланированных встреч с сотрудниками
        </h2>
        <p class="mb-0">
          После добавления нового сотрудника здесь будут отображаться запланированные
          с ним встречи.
        </p>
      </VCol>
    </VRow>
    <VRow v-else-if="contentStatus === contentStatuses.SHOW_TEMPLATES">
      <VCol cols="12">
        <h2 class="mb-3">
          У вас нет шаблона встреч
        </h2>
        <p class="mb-5">
          Чтобы адаптируемые сотрудники получили приглашения на встречи после добавления,
          необходимо создать и настроить шаблон встреч.
        </p>
      </VCol>
    </VRow>
    <VRow v-if="dataLoaded && !fullLoad">
      <VCol>
        <div v-intersect.quiet="getMore" />
      </VCol>
    </VRow>
    <VRow v-if="!dataLoaded && !fullLoad">
      <VCol align="center">
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
/* eslint-disable default-case */
import debounce from 'lodash/debounce';
import { pageLocationToken } from '@/services';
import { formatDate, truncateString } from '@/utils';
import * as snamiApi from '@/services/backend/snami';
import { API_REPORT_CHECKPOINTS } from '@/services/backend/snami/constants';
import { downloadCSVLink } from '@/utils/CSV';

import SRangePicker from '@/components/ui/SRangePicker.vue';
import NewSAvatar from '@/components/ui/NewSAvatar.vue';
import MeetingCheckpointsList from '@/components/meeting/MeetingCheckpointsList.vue';
import MeetingBriefInfo from '@/components/meeting/MeetingBriefInfo.vue';
import MeetingMoreInfo from '@/components/meeting/MeetingMoreInfo.vue';
import MeetingCreateReportForm from '@/components/meeting/MeetingCreateReportForm.vue';
import localStorageInterface from '@/services/localStorage/_utils';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import TextTemplate from '@/components/shared/TextTemplate.vue';
import SelectTags from '@/components/shared/SelectTags.vue';
// import Tags from '@/components/shared/Tags.vue';
import { mapActions } from 'vuex';

const pageReportMeetingStatusToken = localStorageInterface('snami_page_report_meeting_status');
const pageReportMeetingStaffTextToken = localStorageInterface('snami_page_report_meeting_staff_text');
const pageReportMeetingSideStaffTextToken = localStorageInterface('snami_page_report_meeting_side_staff_text');
const pageReportMeetingPeriodToken = localStorageInterface('snami_page_report_meeting_period');

const CHECKPOINT_ROWS = 6;

const contentStatuses = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  SHOW_TABLE: 'SHOW_TABLE',
  SHOW_TEMPLATES: 'SHOW_TEMPLATES',
  SHOW_MEETING: 'SHOW_MEETING',
};

const timeChangeStatus = {
  SUCCESS: 'SUCCES',
  ERROR: 'ERROR',
  CANCELED: 'CANCELED',
};

export default {
  name: 'ReportMeeting',
  components: {
    TextTemplate,
    DialogWrapper,
    SRangePicker,
    NewSAvatar,
    MeetingCheckpointsList,
    MeetingBriefInfo,
    MeetingMoreInfo,
    MeetingCreateReportForm,
    SelectTags,
  },
  inject: ['Names'],
  data() {
    return {
      CHECKPOINT_ROWS,
      startRow: 0,
      contentStatus: contentStatuses.INITIAL,
      contentStatuses,
      filter: {
        is_expired: pageReportMeetingStatusToken.get() || false,
        search: pageReportMeetingStaffTextToken.get() || '',
        start_at_from: '',
        start_at_till: '',
        connected_staff_search: pageReportMeetingSideStaffTextToken.get() || '',
      },
      filterList: [
        { text: 'Все встречи', value: false },
        { text: 'Нужен отчет', value: true },
      ],
      searchPeriod: pageReportMeetingPeriodToken.get() || [],
      dateNow: this.$dayjs().format('YYYY-MM-DD'),
      extraStaffMap: {},
      staffMap: {},
      staff: [],
      locations: [],
      problemsMap: {},
      templateMap: {},
      dataLoaded: true,
      currentLocationId: null,
      maxTemplateLength: 0,
      listWidth: 0,
      page: {},
      activeCheckpoint: {},
      briefInfoDialog: false,
      moreInfoDialog: false,
      createReportDialog: false,
      tags: [
        {
          text: 'Очень большое название чтобы проверить',
          value: 0,
        },
        {
          text: 'Еще одно очень большое название',
          value: 2,
        },
      ],
      selectedTags: [0, 2],
    };
  },
  computed: {
    totalItems() {
      if (this.page === null) {
        return 0;
      } return this.page.total_item;
    },
    fullLoad() {
      return this.staff.length >= this.totalItems;
    },
    currentPage() {
      return this.page.n || 0;
    },
    currentLocationName() {
      if (this.currentLocationId && this.locations.length) {
        const locationIdx = this.locations.findIndex((item) => item.id === this.currentLocationId);
        if (locationIdx !== -1) {
          return this.locations[locationIdx].name;
        }
      }
      return '';
    },
    totalCheckpointsShow() {
      return CHECKPOINT_ROWS + (this.drawer ? 0 : 5);
    },
    showHeaderArrows() {
      return this.maxTemplateLength > CHECKPOINT_ROWS;
    },
    drawer() {
      return this.$store.getters.drawer;
    },
    cellWidth() {
      const listLength = Math.min(this.maxTemplateLength, CHECKPOINT_ROWS);
      return Math.ceil(this.listWidth / listLength);
    },
    headers() {
      let text;
      if (this.showHeaderArrows) {
        text = `${this.startRow + 1} – ${this.startRow + CHECKPOINT_ROWS} задачи`;
      } else {
        text = `1 - ${this.maxTemplateLength} задачи`;
      }
      const headers = [
        {
          sortable: false,
          text: `Сотрудники (${this.totalItems ? this.totalItems : '0'})`,
          align: 'left',
          value: 'staff',
          width: 300,
        },
        {
          sortable: false,
          text,
          align: 'center',
          value: 'report',
        },
      ];
      return headers;
    },
    selectedFilters() {
      let totalFilters = 0;
      if (this.filter.connected_staff_search) totalFilters += 1;
      if (this.filter.start_at_from) totalFilters += 1;
      return totalFilters;
    },
    needMeetingTags() {
      return this.$di.ff.get('meeting_tags');
    },
  },
  created() {
    this.debouncedGetData = debounce(this.handleFilter, 500);
    const loc = pageLocationToken.get();
    if (loc) {
      this.currentLocationId = parseInt(loc, 10);
      this.getData();
    }
    this.$repositories.location.list({}).then((r) => {
      const { data } = r.data;
      this.locations = data;
    }).catch((e) => console.warn(e));
  },
  mounted() {
    if (!this.currentLocationId) {
      this.$refs.locationDropDown.$children[0].isMenuActive = true;
    }
    this.$root.$on('downloadReportMeeting', this.downloadCSV);
  },
  beforeDestroy() {
    this.$root.$off('downloadReportMeeting', this.downloadCSV);
  },
  methods: {
    ...mapActions('customerInfo', ['getCustomerInfo']),
    formatDate,
    truncateString,
    downloadCSV() {
      // ToDo: Убрать костыль, когда будет реализовано на бэке
      const dateNow = new Date();
      const tenYearsBefore = new Date();
      tenYearsBefore.setFullYear(tenYearsBefore.getFullYear() - 10);
      const link = API_REPORT_CHECKPOINTS(
        this.currentLocationId,
        formatDate(tenYearsBefore, 'YYYY-MM-DD'),
        formatDate(dateNow, 'YYYY-MM-DD'),
      );
      downloadCSVLink(link);
    },
    resetSideStaffSearch() {
      this.filter.connected_staff_search = '';
      pageReportMeetingSideStaffTextToken.set(this.filter.connected_staff_search);
      this.debouncedGetData();
    },
    // ******* Dialogs *******************
    showBriefInfo(checkpoint) {
      this.activeCheckpoint = checkpoint;
      this.briefInfoDialog = true;
    },
    showMoreInfo() {
      this.briefInfoDialog = false;
      this.moreInfoDialog = true;
    },
    showCreateReport() {
      this.briefInfoDialog = false;
      this.moreInfoDialog = false;
      this.createReportDialog = true;
    },
    closeCreateReport() {
      this.createReportDialog = false;
    },
    reportCreated() {
      this.createReportDialog = false;
      this.getCustomerInfo();
      this.getData();
    },
    closeMoreInfo(status) {
      this.moreInfoDialog = false;
      if (status) {
        switch (status) {
          case timeChangeStatus.SUCCESS:
            this.$root.$emit('showNotifier', {
              title: 'Встреча перенесена',
              timeout: 6000,
              type: 'success',
            });
            break;
          case timeChangeStatus.ERROR:
            this.$root.$emit('showNotifier', {
              title: 'Дата и время встречи не изменились',
              msg: 'Чтобы сохранить изменения, отредактируйте дату и время и нажмите кнопку «Сохранить».',
              timeout: 6000,
              type: 'error',
            });
            break;
          case timeChangeStatus.CANCELED:
            this.$root.$emit('showNotifier', {
              title: 'Встреча отменена',
              timeout: 6000,
              type: 'warning',
            });
            break;
        }
        this.getData();
      }
    },
    // ***********************************
    prepareCheckpoints(checkpoints) {
      if (!this.showHeaderArrows) {
        return checkpoints;
      }
      return checkpoints.slice(this.startRow, this.startRow + CHECKPOINT_ROWS);
    },
    handleLocation(item) {
      pageLocationToken.set(item.id);
      this.currentLocationId = item.id;
      this.handleFilter();
    },
    handleStatus() {
      pageReportMeetingStatusToken.set(this.filter.is_expired);
      this.handleFilter();
    },
    handleStaffTextSearch() {
      pageReportMeetingStaffTextToken.set(this.filter.search);
      this.debouncedGetData();
    },
    handleSideStaffTextSearch() {
      pageReportMeetingSideStaffTextToken.set(this.filter.connected_staff_search);
      this.debouncedGetData();
    },
    handlePeriodChange(period) {
      this.searchPeriod = period;
      pageReportMeetingPeriodToken.set(this.searchPeriod);
      this.handleFilter();
    },
    handleFilter() {
      this.page = {};
      this.getData();
    },

    // выносим в отдельный метод, что бы не пересчитывать эти же данные при запросе getMore
    handleTemplateAndProblemsData(data) {
      this.templateMap = {};
      data.templates.forEach((item) => {
        this.templateMap[item.id] = item;
      });

      this.problemsMap = {};
      data.problems.forEach((problem) => {
        this.problemsMap[problem.id] = problem;
      });
    },
    handleData(data) {
      if (data) {
        data.extra_staffs.forEach((item) => {
          this.extraStaffMap[item.id] = item;
        });

        const staffWithCheckpoints = data.staffs.map((item) => ({
          ...item,
          checkpoints: [],
        }));
        this.staff = [...this.staff, ...staffWithCheckpoints];

        staffWithCheckpoints.forEach((staff) => {
          this.staffMap[staff.id] = staff;
        });

        data.checkpoints.forEach((item) => {
          const checkpoint = {
            ...item,
            template: this.templateMap[item.template_id],
            problems: item.problems ? item.problems.map((id) => this.problemsMap[id].name) : null,
          };
          this.staffMap[item.staff_id].checkpoints.push(checkpoint);
        });

        let maxTemplateLength = 0;
        this.staff.forEach((staff, i) => {
          if (staff.checkpoints.length > maxTemplateLength) {
            maxTemplateLength = this.staff[i].checkpoints.length;
          }
        });
        this.maxTemplateLength = maxTemplateLength;
      }
    },
    handleLeft() {
      this.startRow = (this.startRow - CHECKPOINT_ROWS < 0)
        ? 0 : this.startRow - CHECKPOINT_ROWS;
    },
    handleRight() {
      this.startRow = (this.startRow + CHECKPOINT_ROWS > this.maxTemplateLength - CHECKPOINT_ROWS)
        ? this.maxTemplateLength - CHECKPOINT_ROWS : this.startRow + CHECKPOINT_ROWS;
    },
    async getData() {
      if (!this.currentLocationId) return;
      this.filter.start_at_from = this.searchPeriod[0] || '';
      this.filter.start_at_till = this.searchPeriod[1] || '';
      this.startRow = 0;
      this.dataLoaded = false;
      this.staff = [];
      this.extraStaffMap = {};
      this.staffMap = {};
      const filter = { ...this.filter, location_id: this.currentLocationId };
      try {
        const {
          data: {
            data,
            page,
          },
        } = await snamiApi.getCheckpointList({
          filter,
          page: this.currentPage,
        });
        this.page = page;
        if (data === null || !data.checkpoints.length) {
          const { data: { data: templates } } = await snamiApi.getCheckpointTemplateList({ filter });
          if (!templates.length) {
            this.contentStatus = contentStatuses.SHOW_TEMPLATES;
          } else {
            this.contentStatus = contentStatuses.SHOW_MEETING;
          }
        } else {
          this.handleTemplateAndProblemsData(data);
          this.handleData(data);
          this.contentStatus = contentStatuses.SHOW_TABLE;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.dataLoaded = true;
      }
      this.onResize();
    },
    async getMore(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.dataLoaded = false;
        const filter = { ...this.filter, location_id: this.currentLocationId };
        try {
          const {
            data: {
              data,
              page,
            },
          } = await snamiApi.getCheckpointList({
            filter,
            page: this.currentPage + 1,
          });
          this.page = page;
          this.handleData(data);
          this.contentStatus = contentStatuses.SHOW_TABLE;
        } catch (e) {
          console.warn(e);
        } finally {
          this.dataLoaded = true;
        }
        this.onResize();
      }
    },
    onResize() {
      this.listWidth = 0;
      this.$nextTick(() => {
        this.calculateListWidth();
      });
    },
    calculateListWidth() {
      if (this.$refs.tutorListCell) {
        const el = this.$refs.tutorListCell.parentNode;
        try {
          const st = window.getComputedStyle(el);
          this.listWidth = el.clientWidth - parseFloat(st.paddingLeft) - parseFloat(st.paddingRight);
        } catch (e) {
          this.listWidth = el.clientWidth;
        }
      }
    },
  },
};
</script>

<style>
.report-meeting .report-meeting__header {
  position: relative;
}
.report-meeting .report-meeting__header .v-icon.v-icon::after {
  display: none;
}
.report-meeting .report-meeting__header-left {
  left: 0;
  position: absolute;
  top: 2px;
}
.report-meeting .report-meeting__header-right {
  right: 0;
  position: absolute;
  top: 2px;
}
</style>
