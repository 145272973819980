<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <VRow>
      <VCol cols="1" />
      <VCol cols="8">
        <VRow>
          <VCol>
            <h1
              data-test="meeting-more-info-template-title"
              class="mb-3 tt-text-headline-1"
            >
              {{ templateName }}
            </h1>
            <div class="d-flex flex-row flex-start align-center">
              <p
                data-test="meeting-more-info-status-text"
                class="tt-text-body-2 tt-light-mono-46--text mb-0"
              >
                {{ statusText }}
              </p>
              <p
                v-if="checkpoint.state === meetingStatus.pending && isWaitingReport"
                class="tt-text-body-2 tt-light-mono-46--text mr-1 mb-0"
              >
                , Отчет
                <VIcon
                  v-if="checkpoint.is_expired"
                  color="error"
                  size="14"
                  class="mx-1"
                >
                  fas fa-exclamation-circle
                </VIcon>
              </p>
              <template v-if="checkpoint.state !== meetingStatus.pending && checkpoint.state !== meetingStatus.failed">
                <p
                  class="tt-text-body-2 tt-light-mono-46--text mb-0"
                >
                  <span
                    data-test="meeting-more-info-checkpoint-meet-date"
                  >{{ formatDate(checkpoint.meet_date) }}</span>
                  <span
                    v-if="checkpoint.meet_time"
                    data-test="meeting-more-info-checkpoint-meet-time"
                  >, {{ checkpoint.meet_time }}</span>
                </p>
                <p
                  class="tt-text-body-2 tt-light-mono-46--text mr-1 mb-0"
                >
                  , Отчет
                  <VIcon
                    color="success"
                    size="14"
                    class="mx-1"
                  >
                    fas fa-check-circle
                  </VIcon>
                </p>
              </template>
            </div>
          </VCol>
        </VRow>
        <VRow
          v-if="checkpoint.state === meetingStatus.pending"
          class="align-center"
        >
          <VCol cols="3">
            <SDatePicker
              v-model="checkpointDate"
              height="44"
              display-format="DD.MM.YYYY"
              append-icon="fal fa-calendar-alt"
              data-test="meeting-more-info-checkpoint-date"
              single-line
              hide-details="auto"
            />
          </VCol>
          <VCol cols="2">
            <CtxTimePicker
              v-model="checkpointTime"
              placeholder="Не указано"
              append-icon="fal fa-clock"
              data-test="meeting-more-info-checkpoint-time"
              height="44"
              single-line
              hide-details="auto"
            />
          </VCol>
          <VCol>
            <TTBtn
              large
              depressed
              color="tt-ghost--text"
              data-test="meeting-more-info-cancel-meeting-button"
              :loading="loading"
              @click="cancelMeeting"
            >
              Отменить встречу
            </TTBtn>
          </VCol>
        </VRow>
        <VRow class="mb-2">
          <VCol>
            <VRow v-if="staff.id">
              <VCol class="pb-2">
                <div class="d-flex">
                  <NewSAvatar
                    data-test="meeting-more-info-staff-avatar"
                    :img="staff.photo_url"
                    :name="`${staff.last_name + ' ' + staff.first_name}`"
                    class="mr-3"
                  />
                  <div class="d-flex flex-column align-start justify-center">
                    <h4
                      class="tt-text-body-2"
                      data-test="meeting-more-info-staff-name"
                    >
                      {{ staff.last_name }} {{ staff.first_name }}
                    </h4>
                    <p class="tt-text-body-2 tt-light-mono-46--text mb-0">
                      Cотрудник
                    </p>
                  </div>
                </div>
              </VCol>
            </VRow>
            <VRow v-if="hr.id">
              <VCol class="pt-2 pb-2">
                <div class="d-flex">
                  <NewSAvatar
                    data-test="meeting-more-info-hr-avatar"
                    :img="getDescription(hr.id).photo_url"
                    :name="`${getDescription(hr.id).last_name + ' ' + getDescription(hr.id).first_name}`"
                    class="mr-3"
                  />
                  <div class="d-flex flex-column align-start justify-center">
                    <h4
                      class="tt-text-body-2"
                      data-test="meeting-more-info-hr-name"
                    >
                      {{ getDescription(hr.id).last_name }} {{ getDescription(hr.id).first_name }}
                    </h4>
                    <p class="tt-text-body-2 tt-light-mono-46--text mb-0">
                      HR
                    </p>
                  </div>
                </div>
              </VCol>
            </VRow>
            <VRow v-if="mentor.id">
              <VCol class="pt-2 pb-2">
                <div class="d-flex">
                  <NewSAvatar
                    data-test="meeting-more-info-mentor-avatar"
                    :img="getDescription(mentor.id).photo_url"
                    :name="`${getDescription(mentor.id).last_name + ' ' + getDescription(mentor.id).first_name}`"
                    class="mr-3"
                  />
                  <div class="d-flex flex-column align-start justify-center">
                    <h4
                      class="tt-text-body-2"
                      data-test="meeting-more-info-mentor-name"
                    >
                      {{ getDescription(mentor.id).last_name }} {{ getDescription(mentor.id).first_name }}
                    </h4>
                    <p
                      class="tt-text-body-2 tt-light-mono-46--text mb-0"
                    >
                      Руководитель
                    </p>
                  </div>
                </div>
              </VCol>
            </VRow>
            <VRow v-if="tutor.id">
              <VCol class="pt-2 pb-2">
                <div class="d-flex">
                  <NewSAvatar
                    data-test="meeting-more-info-tutor-avatar"
                    :img="getDescription(tutor.id).photo_url"
                    :name="`${getDescription(tutor.id).last_name + ' ' + getDescription(tutor.id).first_name}`"
                    class="mr-3"
                  />
                  <div class="d-flex flex-column align-start justify-center">
                    <h4
                      class="tt-text-body-2"
                      data-test="meeting-more-info-tutor-name"
                    >
                      {{ getDescription(tutor.id).last_name }} {{ getDescription(tutor.id).first_name }}
                    </h4>
                    <p class="tt-text-body-2 tt-light-mono-46--text mb-0">
                      Наставник
                    </p>
                  </div>
                </div>
              </VCol>
            </VRow>
            <template v-if="partStaffList.length > 0">
              <VRow
                v-for="(partStaff, index) in partStaffList"
                :key="index"
              >
                <VCol class="pt-2 pb-2">
                  <div class="d-flex">
                    <NewSAvatar
                      data-test="meeting-more-info-part-staff-avatar"
                      :img="getDescription(partStaff.id).photo_url"
                      :name="`${getDescription(partStaff.id).last_name +' ' + getDescription(partStaff.id).first_name}`"
                      class="mr-3"
                    />
                    <div class="d-flex flex-column align-start justify-center">
                      <h4
                        class="tt-text-body-2"
                        data-test="meeting-more-info-part-staff-name"
                      >
                        {{ getDescription(partStaff.id).last_name }} {{ getDescription(partStaff.id).first_name }}
                      </h4>
                      <p
                        class="tt-text-body-2 tt-light-mono-46--text mb-0"
                      >
                        Участник
                      </p>
                    </div>
                  </div>
                </VCol>
              </VRow>
            </template>
          </VCol>
        </VRow>
        <VDivider class="mt-1 mb-4" />
        <template v-if="checkpoint.template.description !== null && checkpoint.template.description.length > 0">
          <VRow>
            <VCol>
              <h3 class="mb-3 tt-text-subtitle">
                Описание встречи
              </h3>
              <p class="mb-0 tt-text-body-2">
                {{ checkpoint.template.description }}
              </p>
            </VCol>
          </VRow>
          <VDivider class="mt-3 mb-4" />
        </template>
        <template v-if="checkpoint.problems !== null && checkpoint.problems.length > 0">
          <VRow>
            <VCol>
              <h3 class="mb-3 tt-text-subtitle">
                Проблемы
              </h3>
              <p
                v-for="(point, index) in checkpoint.problems"
                :key="index"
                class="mb-0 tt-text-body-2"
                data-test="meeting-more-info-checkpoint-problems"
              >
                - {{ point }}
              </p>
            </VCol>
          </VRow>
          <VDivider class="mt-3 mb-4" />
        </template>
        <template v-if="checkpoint.comment !== null && checkpoint.comment.length > 0">
          <VRow>
            <VCol>
              <h3 class="mb-3 tt-text-subtitle">
                Результаты и договоренности
              </h3>
              <p class="mb-0 tt-text-body-2">
                {{ checkpoint.comment }}
              </p>
            </VCol>
          </VRow>
          <VDivider class="mt-3 mb-4" />
        </template>
        <VRow>
          <VCol>
            <div class="d-flex">
              <TTBtn
                v-if="checkpoint.state === meetingStatus.pending"
                depressed
                color="tt-ghost--text"
                large
                class="mr-6"
                data-test="meeting-more-info-create-report-button"
                @click="showCreateReport"
              >
                Заполнить отчет
              </TTBtn>
              <TTBtn
                v-if="checkpoint.state === meetingStatus.pending"
                depressed
                large
                :loading="loading"
                data-test="meeting-more-info-update-meeting-button"
                @click="updateMeeting"
              >
                Сохранить
              </TTBtn>
              <TTBtn
                v-if="checkpoint.state !== meetingStatus.pending"
                large
                color="tt-ghost--text"
                depressed
                class="mr-3"
                data-test="meeting-more-info-handle-close-button"
                @click="handleClose"
              >
                Закрыть
              </TTBtn>
            </div>
          </VCol>
        </VRow>
      </VCol>
      <VCol cols="2" />
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              large
              color="white"
              elevation="1"
              class="mb-2"
              data-test="meeting-more-info-close-button"
              @click="handleClose"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
    <DialogWrapper
      v-model="cancelForm"
      width="496"
      @click:outside="cancelForm = false"
    >
      <VCard
        flat
        class="pa-0"
      >
        <h4 class="tt-text-body-1">
          Не сохранять внесенные изменения?
        </h4>
        <VCardActions class="mt-4 pa-0">
          <div class="d-flex justify-end w-100">
            <TTBtn
              class="mr-3"
              color="tt-ghost--text"
              large
              depressed
              data-test="meeting-more-info-cancel-form-button"
              @click="cancelForm = false"
            >
              Вернуться к отчету
            </TTBtn>
            <TTBtn
              large
              depressed
              data-test="meeting-more-info-cancel-form-close-button"
              @click="closeForm"
            >
              Не сохранять
            </TTBtn>
          </div>
        </VCardActions>
      </VCard>
    </DialogWrapper>
  </VContainer>
</template>

<script>
import NewSAvatar from '@/components/ui/NewSAvatar.vue';
import { meetingStatus, roles } from '@/constants';
import { formatDate } from '@/utils';
import SDatePicker from '@/components/ui/SDatePicker.vue';
import CtxTimePicker from '@/components/ui/CtxTimePicker.vue';
import * as snamiApi from '@/services/backend/snami';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import { mapActions } from 'vuex';

const timeChangeStatus = {
  SUCCESS: 'SUCCES',
  ERROR: 'ERROR',
  CANCELED: 'CANCELED',
};

export default {
  name: 'MeetingMoreInfo',
  components: {
    DialogWrapper,
    SDatePicker,
    CtxTimePicker,
    NewSAvatar,
  },
  props: {
    staff: {
      type: Object,
      required: true,
    },
    extraStaffMap: {
      type: Object,
      default: () => ({}),
    },
    checkpoint: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    meetingStatus,
    checkpointDate: null,
    checkpointTime: null,
    loading: false,
    cancelForm: false,
    createReportFormShow: false,
  }),
  computed: {
    templateName() {
      return this.checkpoint.template.name;
    },
    dateChanged() {
      return this.checkpointDate !== this.checkpoint.meet_date
        || this.checkpointTime !== this.checkpoint.meet_time;
    },
    isWaitingReport() {
      return this.$dayjs(this.checkpoint.meet_date).isSameOrBefore(this.$dayjs());
    },
    mentor() {
      return this.checkpoint.participants.find((item) => item.role === roles.MENTOR) || {};
    },
    hr() {
      return this.checkpoint.participants.find((item) => item.role === roles.HR) || {};
    },
    tutor() {
      return this.checkpoint.participants.find((item) => item.role === roles.TUTOR) || {};
    },
    partStaffList() {
      return this.checkpoint.participants.filter((item) => item.role === roles.STAFF);
    },
    statusText() {
      // ToDo: Дополнить строку статуса исходя из макета
      switch (this.checkpoint.state) {
        case meetingStatus.pending: return 'Запланирована';
        case meetingStatus.withoutProblem:
        case meetingStatus.withProblem: return 'Проведена';
        default: return 'Отменена';
      }
    },
  },
  watch: {
    checkpoint() {
      this.handleData();
    },
  },
  created() {
    this.handleData();
  },
  methods: {
    ...mapActions('customerInfo', ['getCustomerInfo']),
    formatDate,
    getDescription(id) {
      return this.extraStaffMap[id] || {};
    },
    handleData() {
      this.checkpointDate = this.checkpoint.meet_date;
      this.checkpointTime = this.checkpoint.meet_time;
    },
    updateMeeting() {
      this.loading = true;
      snamiApi.updateCheckpoint({
        id: this.checkpoint.id,
        meet_date: this.checkpointDate,
        meet_time: this.checkpointTime,
      }).then(() => {
        this.$emit('close', timeChangeStatus.SUCCESS);
      }).catch((e) => console.warn(e)).finally(() => {
        this.checkpointDate = null;
        this.checkpointTime = null;
        this.loading = false;
      });
    },
    handleClose() {
      if (this.dateChanged === false) {
        this.checkpointDate = null;
        this.checkpointTime = null;
        this.$emit('close');
      } else this.cancelForm = true;
    },
    showCreateReport() {
      if (this.dateChanged === false) {
        this.$emit('showCreateReport');
      } else {
        this.createReportFormShow = true;
        this.cancelForm = true;
      }
    },
    closeForm() {
      if (this.createReportFormShow === true) {
        this.$emit('showCreateReport');
      } else {
        this.checkpointDate = null;
        this.checkpointTime = null;
        this.showCreateReport = false;
        this.cancelForm = false;
        this.$emit('close');
      }
    },
    cancelMeeting() {
      this.loading = true;
      snamiApi.completeCheckpoint({
        id: this.checkpoint.id,
        state: 'FAILED',
      }).then(() => {
        this.$emit('close', timeChangeStatus.CANCELED);
        this.getCustomerInfo();
      }).catch((e) => console.warn(e)).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
::v-deep .v-input__slot {
  padding: 6px 12px!important;
}
::v-deep .v-icon {
  color: #0B1218;
}
</style>
