<template>
  <div>
    <h2
      data-test="meeting-brief-info-template-name"
      class="pb-1 tt-text-title-2"
    >
      {{ templateName }}
    </h2>
    <div v-if="checkpoint.state === meetingStatus.pending">
      <div class="tt-text-body-2 tt-light-mono-46--text pb-2">
        <span data-test="meeting-brief-info-status-text">{{ statusText }}</span>
        <span
          v-if="isWaitingReport && checkpoint.is_expired"
          data-test="meeting-brief-info-is-waiting-report"
        >
          <span>, Отчет</span>
          <VIcon
            color="error"
            size="12"
            class="pl-1"
          >
            fas fa-exclamation-circle
          </VIcon>
        </span>
      </div>
      <div class="d-flex justify-space-between">
        <p
          data-test="meeting-brief-info-meet-time"
          class="mb-0 tt-text-body-2 tt-light-mono-100--text"
        >
          {{ formatDate(checkpoint.meet_date, 'DD MMMM YYYY') }}<span v-if="checkpoint.meet_time">,
            {{ checkpoint.meet_time }}
          </span>
        </p>
        <div>
          <VIcon
            color="tt-black"
            size="16"
            class="ml-1"
            data-test="meeting-brief-info-show-more-button"
            @click="$emit('showMore')"
          >
            fal fa-pen
          </VIcon>
        </div>
      </div>
    </div>
    <div v-else-if="checkpoint.state === meetingStatus.failed">
      <span
        data-test="meeting-brief-info-status-text"
        class="tt-text-body-2 tt-light-mono-46--text pr-1"
      >{{ statusText }}</span>
      <VIcon
        color="tt-light-mono-46"
        size="12"
      >
        far fa-ban
      </VIcon>
    </div>
    <div v-else>
      <p class="tt-text-body-2 tt-light-mono-46--text">
        <span data-test="meeting-brief-info-status-text">{{ statusText }}</span>
        <span data-test="meeting-brief-info-meet-time"> {{ formatDate(checkpoint.meet_date) }}</span>
        <span>
          <span>, Отчет</span>
          <VIcon
            color="success"
            size="12"
            class="ml-1"
          >
            fas fa-check-circle
          </VIcon>
        </span>
      </p>
    </div>
    <VDivider class="mt-3 mb-4" />

    <div class="d-flex mb-4">
      <NewSAvatar
        data-test="meeting-brief-info-staff-avatar"
        :img="staff.photo_url"
        :name="`${staff.last_name} ${staff.first_name}`"
        class="mr-3"
      />
      <div>
        <h4 class="tt-text-body-2">
          {{ staff.last_name }} {{ staff.first_name }}
        </h4>
        <p class="tt-text-body-2 tt-light-mono-46--text mb-0">
          Сотрудник
        </p>
      </div>
    </div>
    <template v-if="hr.id">
      <div class="d-flex mb-4">
        <NewSAvatar
          data-test="meeting-brief-info-hr-avatar"
          :img="getDescription(hr.id).photo_url"
          :name="`${getDescription(hr.id).last_name} ${getDescription(hr.id).first_name}`"
          class="mr-3"
        />
        <div>
          <h4 class="tt-text-body-2">
            {{ getDescription(hr.id).last_name }} {{ getDescription(hr.id).first_name }}
          </h4>
          <p class="tt-text-body-2 tt-light-mono-46--text mb-0">
            HR
          </p>
        </div>
      </div>
    </template>
    <template v-if="mentor.id">
      <div class="d-flex mb-4">
        <NewSAvatar
          data-test="meeting-brief-info-mentor-avatar"
          :img="getDescription(mentor.id).photo_url"
          :name="`${getDescription(mentor.id).last_name} ${getDescription(mentor.id).first_name}`"
          class="mr-3"
        />
        <div>
          <h4 class="tt-text-body-2">
            {{ getDescription(mentor.id).last_name }} {{ getDescription(mentor.id).first_name }}
          </h4>
          <div class="tt-text-body-2 tt-light-mono-46--text mb-0">
            Руководитель
          </div>
        </div>
      </div>
    </template>
    <template v-if="tutor.id">
      <div class="d-flex mb-4">
        <NewSAvatar
          data-test="meeting-brief-info-tutor-avatar"
          :img="getDescription(tutor.id).photo_url"
          :name="`${getDescription(tutor.id).last_name} ${getDescription(tutor.id).first_name}`"
          class="mr-3"
        />
        <div>
          <h4 class="tt-text-body-2">
            {{ getDescription(tutor.id).last_name }} {{ getDescription(tutor.id).first_name }}
          </h4>
          <p class="tt-text-body-2 tt-light-mono-46--text mb-0">
            Наставник
          </p>
        </div>
      </div>
    </template>
    <template v-if="partStaffList.length > 0">
      <div
        v-for="partStaff in partStaffList"
        :key="partStaff.id"
        class="mb-2"
      >
        <div class="d-flex mb-4">
          <NewSAvatar
            data-test="meeting-brief-info-part-staff-avatar"
            :img="getDescription(partStaff.id).photo_url"
            :name="`${getDescription(partStaff.id).last_name} ${getDescription(partStaff.id).first_name}`"
            class="mr-3"
          />
          <div>
            <h4 class="tt-text-body-2">
              {{ getDescription(partStaff.id).last_name }} {{ getDescription(partStaff.id).first_name }}
            </h4>
            <p class="tt-text-body-2 tt-light-mono-46--text mb-0">
              Участник
            </p>
          </div>
        </div>
      </div>
    </template>

    <template v-if="checkpoint.problems !== null && checkpoint.problems.length > 0">
      <VDivider class="mt-3 mb-4" />
      <p class="tt-text-body-2 tt-light-mono-46--text mb-1">
        Проблемы
      </p>
      <p
        v-for="(problem, id) in filteredProblems"
        :key="id"
        class="mb-0 tt-text-body-2 tt-light-mono-100--text"
        data-test="meeting-brief-info-problems"
      >
        - {{ problem }}
      </p>
      <p
        v-if="checkpoint.problems.length >= 5"
        data-test="meeting-brief-info-more-problems"
        class="mb-0 tt-text-body-2 tt-light-mono-100--text"
      >
        Еще {{ checkpoint.problems.length - 3 }}
        {{ pluralize(checkpoint.problems.length - 3, 'проблема', 'проблемы', 'проблем') }}
      </p>
    </template>
    <template v-if="checkpoint.comment">
      <VDivider class="mt-3 mb-4" />
      <p class="tt-text-body-2 tt-light-mono-46--text mb-1">
        Результаты и договоренности
      </p>
      <div
        class="ellipsis ellipsis-four-lines tt-text-body-2 tt-light-mono-100--text"
        data-test="meeting-brief-info-comments"
      >
        {{ checkpoint.comment }}
      </div>
    </template>
    <VDivider class="mt-3 mb-4" />
    <VRow>
      <VCol
        v-if="checkpoint.state === meetingStatus.pending"
        class="d-flex align-center justify-end"
      >
        <TTBtn
          depressed
          color="tt-ghost--text"
          class="mr-5"
          data-test="meeting-brief-info-show-more-button"
          @click="$emit('showMore')"
        >
          Смотреть подробнее
        </TTBtn>
        <TTBtn
          depressed
          data-test="meeting-brief-info-show-create-report-button"
          @click="$emit('showCreateReport')"
        >
          Заполнить отчет
        </TTBtn>
      </VCol>
      <VCol
        v-else
        class="d-flex align-center justify-end"
      >
        <TTBtn
          depressed
          data-test="meeting-brief-info-show-more-button"
          @click="$emit('showMore')"
        >
          Смотреть отчет
        </TTBtn>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { meetingStatus, roles } from '@/constants';
import { formatDate, pluralize } from '@/utils';
import NewSAvatar from '@/components/ui/NewSAvatar.vue';

export default {
  name: 'MeetingBriefInfo',
  components: { NewSAvatar },
  props: {
    staff: {
      type: Object,
      required: true,
    },
    extraStaffMap: {
      type: Object,
      default: () => ({}),
    },
    checkpoint: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    meetingStatus,
  }),
  computed: {
    filteredProblems() {
      if (this.checkpoint.problems.length === 4) {
        return this.checkpoint.problems;
      }
      return this.checkpoint.problems.slice(0, 3);
    },

    templateName() {
      return this.checkpoint.template.name;
    },
    isWaitingReport() {
      return this.$dayjs(this.checkpoint.meet_date).isSameOrBefore(this.$dayjs());
    },
    mentor() {
      return this.checkpoint.participants.find((item) => item.role === roles.MENTOR) || {};
    },
    hr() {
      return this.checkpoint.participants.find((item) => item.role === roles.HR) || {};
    },
    tutor() {
      return this.checkpoint.participants.find((item) => item.role === roles.TUTOR) || {};
    },
    partStaffList() {
      return this.checkpoint.participants.filter((item) => item.role === roles.STAFF);
    },
    statusText() {
      switch (this.checkpoint.state) {
        case meetingStatus.pending: return 'Запланирована';
        case meetingStatus.withoutProblem:
        case meetingStatus.withProblem: return 'Проведена';
        default: return 'Отменена';
      }
    },
  },
  methods: {
    pluralize,
    formatDate,
    getDescription(id) {
      return this.extraStaffMap[id] || {};
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
