<template>
  <div
    class="y-flex flex-column align-start cursor--pointer px-3"
    data-test="checkpoint-cell"
    @click="$emit('click', item)"
  >
    <div>{{ meetDate }}</div>
    <p
      v-if="isAwaiting"
      class="tt-black--text text--lighten-2 mb-0"
    >
      {{ meetTime }}
    </p>
    <div
      v-else
      class="d-flex align-baseline"
    >
      <p class="tt-black--text text--lighten-2 mr-1 mb-0">
        Отчет
      </p>
      <VIcon
        v-if="item.is_expired"
        color="error"
        size="12"
      >
        fas fa-exclamation-circle
      </VIcon>
      <VIcon
        v-else-if="item.state === checklistStatuses.FAILED"
        color="tt-light-mono-46"
        size="12"
      >
        far fa-ban
      </VIcon>
      <VIcon
        v-else
        color="#00D358"
        size="12"
      >
        fas fa-check-circle
      </VIcon>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils';

const checklistStatuses = {
  PENDING: 'PENDING',
  WITHOUT_PROBLEM: 'WITHOUT_PROBLEM',
  FAILED: 'FAILED',
};

export default {
  name: 'MeetingCheckpointsCell',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { checklistStatuses };
  },
  computed: {
    meetDate() {
      return formatDate(this.item.meet_date);
    },
    meetTime() {
      return this.item.meet_time;
    },
    isAwaiting() {
      return this.item.state === checklistStatuses.PENDING && !this.item.is_expired;
    },
    status() {
      switch (this.item.state) {
        case checklistStatuses.FAILED:
          return {
            color: '#FF3E00',
            icon: 'fas fa-times-circle',
            text: 'Не выполнено',
          };
        case checklistStatuses.WITHOUT_PROBLEM:
          return {
            color: '#00D358',
            icon: 'fas fa-check-circle',
            text: 'Проведено',
          };
        case checklistStatuses.PENDING:
        default:
          return {
            color: '#D8D9DA',
            text: 'Ожидается',
          };
      }
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
