import * as snamiApi from '@/services/backend/snami';

// eslint-disable-next-line import/prefer-default-export
export const downloadCSVLink = async (link) => {
  try {
    const res = await snamiApi.signLink({ link });
    const { signed_link: signedLink } = res.data;

    const linkElem = document.createElement('a');

    linkElem.setAttribute('href', `${process.env?.VUE_APP_ONBOARDING_API_ADDRESS}${signedLink.replace(/^\//, '')}`);
    document.body.appendChild(linkElem); // Required for FF
    linkElem.click(); // This will download the data file.
    document.body.removeChild(linkElem);
  } catch (e) {
    console.warn(e);
  }
};
