<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <VRow>
      <VCol cols="1" />
      <VCol cols="8">
        <VRow>
          <VCol>
            <h1
              class="tt-text-headline-1"
              data-test="meeting-create-report-template-title"
            >
              {{ templateName }}
            </h1>
          </VCol>
        </VRow>
        <VRow class="mb-2">
          <VCol>
            <VRow v-if="staff.id">
              <VCol class="pb-2">
                <div class="d-flex">
                  <NewSAvatar
                    data-test="meeting-create-report-staff-avatar"
                    :img="staff.photo_url"
                    :name="`${staff.last_name + ' ' + staff.first_name}`"
                    class="mr-3"
                  />
                  <div class="d-flex flex-column align-start justify-center">
                    <h4
                      class="tt-text-body-2"
                      data-test="meeting-create-report-staff-name"
                    >
                      {{ staff.last_name }} {{ staff.first_name }}
                    </h4>
                    <p
                      class="tt-text-body-2 tt-light-mono-46--text mb-0"
                    >
                      Cотрудник
                    </p>
                  </div>
                </div>
              </VCol>
            </VRow>
            <VRow v-if="hr.id">
              <VCol class="pt-2 pb-2">
                <div class="d-flex">
                  <NewSAvatar
                    data-test="meeting-create-report-hr-avatar"
                    :img="getDescription(hr.id).photo_url"
                    :name="`${getDescription(hr.id).last_name + ' ' + getDescription(hr.id).first_name}`"
                    class="mr-3"
                  />
                  <div class="d-flex flex-column align-start justify-center">
                    <h4
                      class="tt-text-body-2"
                      data-test="meeting-create-report-hr-name"
                    >
                      {{ getDescription(hr.id).last_name }} {{ getDescription(hr.id).first_name }}
                    </h4>
                    <p
                      class="tt-text-body-2 tt-light-mono-46--text mb-0"
                    >
                      HR
                    </p>
                  </div>
                </div>
              </VCol>
            </VRow>
            <VRow v-if="mentor.id">
              <VCol class="pt-2 pb-2">
                <div class="d-flex">
                  <NewSAvatar
                    data-test="meeting-create-report-mentor-avatar"
                    :img="getDescription(mentor.id).photo_url"
                    :name="`${getDescription(mentor.id).last_name + ' ' + getDescription(mentor.id).first_name}`"
                    class="mr-3"
                  />
                  <div class="d-flex flex-column align-start justify-center">
                    <h4
                      class="tt-text-body-2"
                      data-test="meeting-create-report-mentor-name"
                    >
                      {{ getDescription(mentor.id).last_name }} {{ getDescription(mentor.id).first_name }}
                    </h4>
                    <p
                      class="tt-text-body-2 tt-light-mono-46--text mb-0"
                    >
                      Руководитель
                    </p>
                  </div>
                </div>
              </VCol>
            </VRow>
            <VRow v-if="tutor.id">
              <VCol class="pt-2 pb-2">
                <div class="d-flex">
                  <NewSAvatar
                    data-test="meeting-create-report-tutor-avatar"
                    :img="getDescription(tutor.id).photo_url"
                    :name="`${getDescription(tutor.id).last_name + ' ' + getDescription(tutor.id).first_name}`"
                    class="mr-3"
                  />
                  <div class="d-flex flex-column align-start justify-center">
                    <h4
                      class="tt-text-body-2"
                      data-test="meeting-create-report-tutor-name"
                    >
                      {{ getDescription(tutor.id).last_name }} {{ getDescription(tutor.id).first_name }}
                    </h4>
                    <p
                      class="tt-text-body-2 tt-light-mono-46--text mb-0"
                    >
                      Наставник
                    </p>
                  </div>
                </div>
              </VCol>
            </VRow>
            <template v-if="partStaffList.length > 0">
              <VRow
                v-for="(partStaff, index) in partStaffList"
                :key="index"
              >
                <VCol class="pt-2 pb-2">
                  <div class="d-flex">
                    <NewSAvatar
                      data-test="meeting-create-report-part-staff-avatar"
                      :img="getDescription(partStaff.id).photo_url"
                      :name="`${getDescription(partStaff.id).last_name +' '+ getDescription(partStaff.id).first_name}`"
                      class="mr-3"
                    />
                    <div class="d-flex flex-column align-start justify-center">
                      <h4
                        class="tt-text-body-2"
                        data-test="meeting-create-report-part-staff-name"
                      >
                        {{ getDescription(partStaff.id).last_name }} {{ getDescription(partStaff.id).first_name }}
                      </h4>
                      <p
                        class="tt-text-body-2 tt-light-mono-46--text mb-0"
                      >
                        Участник
                      </p>
                    </div>
                  </div>
                </VCol>
              </VRow>
            </template>
          </VCol>
        </VRow>
        <VDivider class="mt-1 mb-4" />
        <template v-if="checkpoint.template.description !== null && checkpoint.template.description.length > 0">
          <VRow>
            <VCol>
              <h3 class="mb-3 tt-text-subtitle">
                Описание встречи
              </h3>
              <p
                data-test="meeting-create-report-checkpoint-description"
                class="mb-0 tt-text-body-2"
              >
                {{ checkpoint.template.description }}
              </p>
            </VCol>
          </VRow>
          <VDivider class="mt-3 mb-4" />
        </template>
        <VRow>
          <VCol>
            <h3 class="mb-3 tt-text-subtitle">
              Укажите проблемы при их наличии
            </h3>
            <VCheckbox
              v-for="(item, index) in availableProblemsList"
              :key="index"
              v-model="problems"
              :value="item.id"
              class="pa-0"
              color="#00A0F2"
              hide-details
              data-test="meeting-create-report-problems-checkbox"
            >
              <template #label>
                <p class="tt-text-body-2 mb-0">
                  {{ item.name }}
                </p>
              </template>
            </VCheckbox>
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <h3 class="mb-3 tt-text-subtitle">
              Результаты и договоренности
            </h3>
            <TTTextarea
              :maxlength="10000"
              hide-details="auto"
              auto-grow
              data-test="meeting-create-report-comment"
              placeholder="Введите итоги встречи"
              :error="validation('comment').status"
              :error-messages="validation('comment').value"
              :value="comment"
              @input="v => handleChange('comment', v)"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <div class="d-flex">
              <TTBtn
                large
                color="tt-secondary"
                depressed
                class="mr-6"
                data-test="meeting-create-report-handle-close-button"
                @click="handleClose"
              >
                Отменить
              </TTBtn>
              <TTBtn
                depressed
                large
                :loading="saving"
                data-test="meeting-create-report-save-report-button"
                @click="saveReport"
              >
                Добавить
              </TTBtn>
            </div>
          </VCol>
        </VRow>
      </VCol>
      <VCol cols="2" />
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              large
              color="white"
              elevation="1"
              class="mb-2 v-btn--aside"
              data-test="meeting-create-report-close-button"
              @click="() => handleClose()"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
    <DialogWrapper
      v-model="cancelForm"
      width="496"
      @click:outside="cancelForm = false"
    >
      <VCard
        flat
        class="pa-0"
      >
        <h4 class="tt-text-body-1">
          Не сохранять внесенные изменения?
        </h4>
        <VCardActions class="mt-4 pa-0">
          <div class="d-flex justify-end w-100">
            <TTBtn
              color="tt-ghost--text"
              large
              class="mr-3"
              depressed
              data-test="meeting-create-report-cancel-form-button"
              @click="cancelForm = false"
            >
              Вернуться к отчету
            </TTBtn>
            <TTBtn
              depressed
              large
              data-test="meeting-create-report-close-form-button"
              @click="closeForm"
            >
              Не сохранять
            </TTBtn>
          </div>
        </VCardActions>
      </VCard>
    </DialogWrapper>
  </VContainer>
</template>

<script>
import NewSAvatar from '@/components/ui/NewSAvatar.vue';
import { meetingStatus, roles } from '@/constants';
import * as snamiApi from '@/services/backend/snami';
import { validation, handleError } from '@/services';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';

export default {
  name: 'MeetingCreateReportForm',
  components: { DialogWrapper, NewSAvatar },
  props: {
    staff: {
      type: Object,
      required: true,
    },
    extraStaffMap: {
      type: Object,
      default: () => ({}),
    },
    checkpoint: {
      type: Object,
      required: true,
    },
    problemsMap: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      meetingStatus,
      validationStatus: [],
      cancelForm: false,
      problems: [],
      comment: '',
      saving: false,
    };
  },
  computed: {
    templateName() {
      return this.checkpoint.template.name;
    },
    mentor() {
      return this.checkpoint.participants.find((item) => item.role === roles.MENTOR) || {};
    },
    hr() {
      return this.checkpoint.participants.find((item) => item.role === roles.HR) || {};
    },
    tutor() {
      return this.checkpoint.participants.find((item) => item.role === roles.TUTOR) || {};
    },
    partStaffList() {
      return this.checkpoint.participants.filter((item) => item.role === roles.STAFF);
    },
    availableProblemsList() {
      return this.checkpoint.available_problems
        .filter((id) => !!this.problemsMap[id])
        .map((id) => this.problemsMap[id]);
    },
  },
  methods: {
    validation,
    handleError,
    async saveReport() {
      this.saving = true;
      const state = (this.problems.length === 0) ? meetingStatus.withoutProblem : meetingStatus.withProblem;
      const data = {
        comment: this.comment,
        state,
        problems: this.problems,
      };
      await snamiApi.completeCheckpoint({
        id: this.checkpoint.id,
        ...data,
      }).then(() => {
        this.$emit('created', {
          staffId: this.checkpoint.staff_id,
          templateId: this.checkpoint.template_id,
          ...data,
        });
        this.closeForm();
      }).catch((e) => {
        this.handleError(e);
      }).finally(() => {
        this.saving = false;
      });
    },
    getDescription(id) {
      return this.extraStaffMap[id] || {};
    },
    handleClose() {
      if (this.problems.length === 0 && this.comment.length === 0) {
        this.closeForm();
      } else this.cancelForm = true;
    },
    closeForm() {
      this.cancelForm = false;
      this.problems = [];
      this.comment = '';
      this.$emit('close');
    },
    handleChange(name, value, target) {
      this.comment = value || '';
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
  },
};
</script>

<style lang="scss">
.report-form__subtitle {
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.696471px;
  color: #0B1218;
}
.report-form__color-light-mono-46 {
  color: #8F9295;
}
::v-deep .theme--light.v-icon {
  color: #00A0F2;
}
::v-deep .v-input__slot .v-text-field__slot {
  padding: 4px 4px;
}
</style>
